import "./ForgotPasswordEmail.scss";
import EnvelopeImg from "../../ui/images/envelope.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendEmail } from "../../../store/account/accountActions";
import { clearStatusState } from "../../../store/account/accountSlice";
import { useTranslation } from "react-i18next";

const ForgotPasswordEmail = () => {
  const { loading, status, error } = useSelector((state) => state.account);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [t] = useTranslation("global");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearStatusState());
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(sendEmail({ email, navigate }));
    if (sendEmail.rejected.match(result)) {
      if (result.payload.email) {
        setErrorMessage(result.payload.email[0]);
      } else if (result.payload.error) {
        setErrorMessage(result.payload.error);
      } else {
        setErrorMessage("Произошла ошибка при отправке электронной почты");
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="forget container">
          <img src={EnvelopeImg} alt="envelope" className="forget__img" />
          <h2 className="forget__title">{t("forgotPasswordEmail.enter")}</h2>
          <p className="forget__info">{t("forgotPasswordEmail.desc")}</p>
          <p className="forget__code">Email</p>
          <input
            className="forget__input"
            type="email"
            value={email}
            placeholder={t("forgotPasswordEmail.enter")}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className="forget__error-text">
            {errorMessage === "Введите правильный адрес электронной почты." &&
              t("forgotPasswordEmail.emailFormat")}
            {errorMessage === "wrong email" &&
              t("forgotPasswordEmail.userNotFound")}
            {errorMessage && !["Введите правильный адрес электронной почты.", "wrong email"].includes(errorMessage) &&
              errorMessage}
          </div>
          <button className="forget__btnActive" onClick={handleEmailSubmit}>
            {t("forgotPasswordEmail.sendCode")}
          </button>
        </div>
      )}
    </>
  );
};

export default ForgotPasswordEmail;
